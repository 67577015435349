import React from "react"
import { graphql, Link } from "gatsby"
import BlogNavBar from '../components/BlogNavBar'
import SheetkraftFooter from '../components/SheetkraftFooter.js';
import LetsTalk from '../components/LetsTalk';
import Img from 'gatsby-image'
import '../styles/main.css'
import { Helmet } from 'react-helmet';
import styles from './blogpost.module.css';
import logo from '../../src/images/Logo.png';
import Share from '../components/Share';

import AnchorLink from 'react-anchor-link-smooth-scroll'

function RecentPost({ nodes }) {
  let author = nodes.frontmatter.author.replace(/ /g, "-").toLowerCase();
  //let publishedDate = nodes.frontmatter.published;
  return (
    <ul style={{ padding: '0' }}>
      <li style={{ listStyleType: 'none' }}>
        <Link to={nodes.fields.slug} className={styles.textDecoration}>
          <h4 className={styles.frontmatterTitle} style={{ paddingTop: '0', paddingLeft: '0', marginBottom: '0' }}>
            {nodes.frontmatter.title}</h4>
        </Link>
        <p className={styles.frontmatterPublished} style={{ padding: '0' }}>
          <Link
            to={`/blog/author/${author}/`}
            className={styles.frontmatterAuthor} style={{ padding: '0' }}
          >
            - {nodes.frontmatter.author}
          </Link>
          {/* <span>&bull; </span>
          {publishedDate} */}
        </p>

      </li>
    </ul>
  );
}

export default class BlogPost extends React.Component {

  constructor(props) {
    super(props);
    //for scroll
    this.handleScroll = this.handleScroll.bind(this);
  }

  //for scroll
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    let sk_sticky = document.querySelector('.sk-adaptive-header');
    sk_sticky.classList.add('sk-is-sticky');

    if (document.documentElement.scrollTop === 0) {
      sk_sticky.classList.remove('sk-is-sticky');
    }
  }

  render() {
    const headingStyle = {

      color: '#000',
      fontWeight: 'bold'
    }

    const post = this.props.data.markdownRemark;
    let author = post.frontmatter.author.replace(/ /g, '-').toLowerCase();

    let totalPosts = this.props.pageContext.blogPosts.sort(function (a, b) {
      return new Date(b.node.published || b.node.frontmatter.published) - new Date(a.node.published || a.node.frontmatter.published);
    });

    let recentPosts = totalPosts.filter(p => p.node.frontmatter.category === post.frontmatter.category && p.node.frontmatter.title !== post.frontmatter.title).map(post => post.node).slice(0, 4);

    const gotoContactBlogPost = {
      position: 'fixed',
      top: '47%',
      left: '0px',
      background: '#4472c4',
      color: '#fff',
      padding: '10px',
      transition: 'linear .2s',
      zIndex: '2',
      cursor: 'pointer'
    }

    return (

      <div>

        <Helmet>
          <meta name="description" property="og:description" content="SheetKraft builds high quality web-based applications using pre-designed powerful building blocks and connects to diverse sources of data across all your internal business applications" />
          <meta name="title" property="og:title" content="SheetKraft" />
          <meta name="image" property="og:image" content={logo} />
          <meta name="author" content="SheetKraft" />
          < meta name="keywords"
            content=" automation, automation anywhere, uipath, rpa, robotic process automation, automate processes, robotic automation process, rpa services, rpa software, rpa tools, blue prismno code, rapid application development,business process automation" />
          <title>SheetKraft - Blog</title>
        </Helmet>

        <BlogNavBar currentCategory={post.frontmatter.category} />

        <div className={styles.wrapper}>
          <div className={styles.textCenter}>
            <h1 className={styles.frontmatterTitle} >{post.frontmatter.title}</h1>
            <p className={styles.colorGrey} >
              Posted under {
                <Link to={`/blog/category/${post.frontmatter.category}/`} className={styles.capitalize}>{post.frontmatter.category.replace(/-/, " ")}</Link>
              } on {post.frontmatter.published} by <Link to={`/blog/author/${author}/`} className={styles.red}>{post.frontmatter.author}</Link>
            </p>
          </div>
          <div className="share" style={{ textAlign: 'center' }}>
            <Share
              socialConfig={{
                config: {
                  url: `${this.props.data.site.siteMetadata.siteUrl}${this.props.pageContext.slug}`,
                  title: this.props.data.markdownRemark.frontmatter.title
                },
              }}
            />

          </div>
          <br />
          {/* {!!post.frontmatter.hero && !!post.frontmatter.hero.childImageSharp ? <Img fluid={post.frontmatter.hero.childImageSharp.fluid} className={styles.maxHeight} imgStyle={{ objectFit: "none" }} />  style={{ display: post.frontmatter.category === "news" ? 'none' : 'block' }}/> : ''} */}
          {!!post.frontmatter.hero && !!post.frontmatter.hero.childImageSharp ? < Img fluid={post.frontmatter.hero.childImageSharp.fluid} imgStyle={{ objectFit: "none" }} style={{ display: post.frontmatter.category === "news" ? 'none' : 'block' }} /> : ''}

          <div dangerouslySetInnerHTML={{ __html: post.html }} className={styles.textLeft} id="postContent" />
          <br />
          <hr className={styles.lightGrey} />
          <p className="tagged" >TAGGED:
                {
              post.frontmatter.tags.map((tag) => (
                <Link key={tag} to={`/blog/tag/${tag}/`} className="tagsList" >{tag}</Link>
              ))
            }
          </p>



          <hr className={styles.lightGrey} />

          <div className={styles.similarPosts}>
            <div >
              <h3 className={styles.recentPostsPadding} style={headingStyle}>Recent Posts</h3>
              <hr className={styles.lightGrey} />
            </div>

            <div className={styles.gridsStyles} >
              {
                recentPosts.map((node, index) => (
                  <RecentPost nodes={node} key={index} />
                ))

              }

            </div>
          </div>
        </div>

        <LetsTalk />
        <SheetkraftFooter />
        <AnchorLink href='#contactForm' className="scrolldown" id="gotoContactBlogPost" style={gotoContactBlogPost}> < i className="fa fa-phone"> </i> </AnchorLink>
      </div>

    )
  }
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        category
        tags
        published(formatString: "MMMM DD , YYYY")
        hero {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        author
      }
    }
    site {
      id
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`